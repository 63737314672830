import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: "ro",
    fallbackLocale: "en",
    messages: {
        en: {
            //ivite page
            "Welcome to Salex": "Welcome to Salex",
            "Where opportunities and offers align!": "Where opportunities and offers align!",
            "Enter your invitation code to continue": "Enter your invitation code to continue",
            "Invitation Code": "Invitation Code",
            "Continue": "Continue",
            "For more information, please contact us at": "For more information, please contact us at",
            "Invalid invitation code": "Invalid invitation code",

            //page titles
            "home.title": "Salex - Free Classified Ads",
            "home.description": "Explore Salex - your hub for free classified ads. Buy, sell, and discover a variety of listings from electronics to real estate. Join our community for easy, efficient classified experiences.",

            "category.title": "Salex - Free Classified Ads",
            "category.description": "Browse diverse categories on Salex: Find and list items, services, and real estate. Easy navigation, vast selection. Elevate your buying and selling experience.",

            "add.title": "Add Advertisement - Salex",
            "add.description": "Post your ad on Salex: Quick, simple, and free. Reach a wide audience with your listings in various categories, from electronics to real estate. Amplify your sales today!",

            "edit.title": "Edit Advertisement - Salex",
            "edit.description": "Edit descriptions, update details, and enhance your listing for better visibility and engagement. Make your ad stand out!",

            "404.title": "404 - Page not found",
            "404.description": "This item might not exist or is no longer available",

            "pricing.title": "Pricing - Salex",
            "pricing.description": "Discover affordable pricing plans on Salex. Choose the perfect option for your advertising needs, from free listings to premium features. Maximize value and reach.",

            "recent.title": "Recent - Salex",
            "recent.description": "Explore the latest listings on Salex: Stay ahead with the most recent ads in various categories. Find fresh deals and opportunities every day.",

            "search.title": "Search - Salex",
            "search.description": "Find exactly what you need on Salex with our powerful search. Discover a wide range of listings from various categories, tailored to your preferences.",

            "preview.title": "Preview - Salex",
            "preview.description": "Preview your ad before publishing. Make sure everything is in order and ready to go.",

            "adverts.title": "My Adverts - Salex",
            "adverts.description": "Manage your adverts on Salex: Edit, promote, and delete your listings.",

            "dashboard.title": "Dashboard - Salex",
            "dashboard.description": "Manage your account on Salex: Update your profile, change your password, and manage your notifications. Track your sales and performance.",

            "messages.title": "Messages - Salex",
            "messages.description": "Manage your messages on Salex: View, reply, and delete your messages.",

            "profile.title": "Profile - Salex",
            "profile.description": "Manage your profile on Salex: Update your profile picture, change your email, and manage your notifications.",

            "notifications.title": "Notifications - Salex",
            "notifications.description": "Manage your notifications on Salex: Update your notification settings and preferences.",

            "reviews.title": "Reviews - Salex",
            "reviews.description": "Manage your reviews on Salex: View, reply, and delete your reviews.",

            "favorites.title": "Favorites - Salex",
            "favorites.description": "Manage your favorites on Salex: View, add, and delete your favorites.",

            "helpdesk.title": "Help Desk - Salex",
            "helpdesk.description": "Salex Helpdesk - Support and Assistance for Your Queries",

            //text
            "Hello": "Who searches will always find!",
            //menu
            "Home": "Home",
            "About": "About",
            "Contact": "Contact",
            "Login": "Login",
            "Logout": "Logout",
            "Register": "Register",
            "Account": "Account",
            "Adverts": "My Ads",
            "Favorites": "Favorites",
            "Messages": "Messages",
            "Message": "Message",
            "My Profile": "My Profile",
            "Bills": "Bills",
            "History": "History",
            "Payment Methods": "Payment Methods",
            "Notifications": "Notifications",
            "Password": "Password",
            "Settings": "Settings",
            "Dashboard": "Dashboard",
            "Personal Data": "Personal Data",
            "Reviews": "Reviews",

            //footer sections
            "Information": "Information",
            "Customer Support": "Customer Support",
            "Social Media": "Social Media",
            
            //interface elements
            "Search": "Search",
            "Location": "Location",
            "isType": "Type",
            "Categories": "Categories",
            "postAd": "Post Ad",
            "Generate Description": "Generate Description",
            "Show mobile": "Show your mobile number to the buyer?",
            "Save Draft": "Save Draft",
            "Publish": "Publish",
            "Previous": "Previous",
            "Next": "Next",
            "Images": "Images",
            "Drop images": "Drop your images here or click to upload",
            "Continue to next step": "Continue to next step",
            "Cancel": "Cancel",
            "Save": "Save",
            "Delete": "Delete",
            "Back": "Back",
            "Contact": "Contact",
            "Send Message": "Send Message",
            "SMessage": "Send Message",
            "Send": "Send",
            "Edit": "Edit",
            "View": "View",
            "View Ad": "View Ad",
            "View Profile": "View Profile",
            "View Message": "View Message",
            "Reply": "Reply",
            "Date": "Date",
            "View Gallery": "View Gallery",
            "Advanced Search": "Advanced Search",
            "Close": "Close",
            "Apply Filter": "Apply Filter",
            "Clear": "Clear",
            "Clear filters": "Clear filters",
            "Grid": "Grid",
            "List": "List",
            "Reset Filter": "Reset Filter",
            "Total views": "Total views",
            "Report": "Report",
            "Your Recently Viewed Items": "Your Recently Viewed Items",
            "Similar Items": "Similar Items",
            "View All": "View All",
            "Restore Access": "Restore Access",
            "Resend Code": "Resend Code",
            "Save Password": "Save Password",
            "Your Email": "Your Email",
            "Your Phone": "Your Phone",
            "Verification code": "Verification code",
            "Create New Password": "Create New Password",
            "Your New Password": "Your New Password",
            "Login Now": "Login Now",
            "Try Another Phone Number": "Try Another Phone Number",
            "Resend Code": "Resend Code",
            "Save Password": "Save Password",
            "Your phone": "Your phone",
            "Your password": "Your password",
            "Forgot password?": "Forgot password?",
            "Create an Account?": "Create an Account?",
            "Phone": "Phone",
            "Password": "Password",
            "Username": "Username",
            "Full Name": "Full Name",
            "Address": "Address",
            "City": "City",
            "Change": "Change",
            "Change Email": "Change Email",
            "Add": "Add",
            "Add Email": "Add Email",
            "Update": "Update",
            "Change Password": "Change Password",
            "Current Password": "Current Password",
            "New Password": "New Password",
            "Your answer": "Your answer",
            "Change Phone": "Change Phone",
            "Restore Acces": "Restore Acces",
            "Help Desk": "Help Desk",
            "Grid": "Grid",
            "List": "List",
            "Incoming": "Incoming",
            "Outgoing": "Outgoing",
            "Type your message": "Type your message",
            "Chat": "Chat",
            "Map": "Map",
            "Select Address": "Select Address",
            "Save Location": "Save Location",
            "Generate": "Generate",
            "Mobile Uploader": "Mobile Uploader",
            "Done": "Done",
            "Upload Queue": "Upload Queue",
            "Remove": "Remove",
            "Upload Images": "Upload Images",
            "Main": "Main",
            "Reset": "Reset",
            "Add Images": "Add Images",
            "Filter": "Filter",
            "Sort": "Sort",
            "Help": "Help",
            "Help Bot": "Help Bot",
            "Help Desk": "Help Desk",
            "Leave a Review": "Leave a Review",
            "Contact information": "Contact information",
            "Post a Review": "Post a Review",
            "Post": "Post",
            "stars": "stars",
            "by": "by",
            "Send a Report": "Send a Report",
            "report1": "Misleading or False Information",
            "report2": "Inappropriate or Offensive Content",
            "report3": "Scam or Fraudulent Activity",
            "report4": "Irrelevant or Off-topic Content",
            "report5": "Harmful or Dangerous Products",
            "report6": "Repetitive or Spammy Content",
            "report10": "Other",
            "Send Offer": "Send Offer",
            "Your Price": "Your Price",
            "Make an Offer": "Make an Offer",
            "Generating Description": "Generating Description",
            "Profile Picture": "Profile Picture",
            "Upload Image": "Upload Image",
            "Upload Avatar": "Upload Avatar",
            "Your email": "Your email",
            "Flag": "Flag",
            "Add Filters": "Add Filters",
            "No adverts found": "No adverts found",
            "View Subcategories": "View Subcategories",
            "Hide": "Hide",
            "Negociable": "Negociable",
            "Sort": "Sort",
            "By Date: Newest": "By Date: Newest",
            "By Date: Oldest": "By Date: Oldest",
            "By Price: Low to High": "By Price: Low to High",
            "By Price: High to Low": "By Price: High to Low",
            "No Sort": "No Sort",
            "Status": "Status",
            "Preview": "Preview",
            "Promote": "Promote",
            "Temporarily Suspended": "Temporarily Suspended",
            "Rejected": "Rejected",
            "Under Review": "Under Review",
            "Archived": "Archived",
            "Unknown": "Unknown",
            "Published": "Published",
            "Add Advert": "Add Advert",
            "Delete Account": "Delete Account",
            "Yes": "Yes",
            "No": "No",
            "Manage Account": "Manage Account",
            "Try Again?": "Try Again?",
            "Register with a Callback": "Register with a Callback",


            //modals
            "Exchange Rates": "Exchange Rates",
            //sections
            "Details": "Details",
            "Select category": "Select category",
            "Select subcategory": "Select subcategory",
            "Price": "Price",
            "Description": "Description",
            "Advert Images": "Advert Images",
            "Edit Your Advert": "Edit Your Advert",
            //forms
            "Options": "Options",
            "Category": "Category",
            "Subcategory": "Subcategory",
            "Currency": "Currency",
            "Select": "Select",
            "Select Model": "Select Model",
            "Model": "Model",
            "Title": "Title",
            "Description": "Description",
            "Price Max": "Price Max",
            "Price Min": "Price Min",
            "from": "from",
            "to": "to",
            "Negotiable": "Negotiable",
            "Phone": "Phone",
            "Email": "Email",
            "Create a new account": "Create a new account",
            "Existing account login": "Existing account login",
            "Account Type": "Account Type",
            "Personal": "Personal",
            "Company": "Company",
            "Your Username": "Your Username",
            "Your Password": "Your Password",
            "Your Name": "Your Name",
            "Your Phone": "Your Phone",
            //dialog messages & titles
            "Deleting Image": "Deleting Image",
            "delete image message" : "Are you sure you want to <b>delete</b> this image? This action cannot be undone.",
            "Promote Advert": "Promote Advert",
            "You can only promote your advert once per day.": "You can only promote your advert once per day.",
            "You have no promotions left for this Advert.": "You have no promotions left for this Advert.",
            "Are you sure you want to <b>promote</b> this advert?": "Are you sure you want to <b>promote</b> this advert?",
            "OK": "OK",
            "Promote": "Promote",
            "Promote Advert": "Promote Advert",
            "Are you sure you want to delete this advert? This action cannot be undone.": "Are you sure you want to delete this advert? This action cannot be undone.",
            "You can only promote your advert once per day.": "You can only promote your advert once per day.",
            "Delete review": "Delete review",
            "Are you sure you want to delete this review? This action cannot be undone.": "Are you sure you want to delete this review? This action cannot be undone.",
            "Flag review": "Flag review",
            "Are you sure you want to flag this review?": "Are you sure you want to flag this review?",
            "Are you sure you want to promote this advert?": "Are you sure you want to promote this advert?",

            "cookie.title": "Cookie Policy",
            "cookie.message": "This website uses cookies to ensure you get the best experience on our website.",
            "cookie.confirm": "Got it!",
            "cookie.cancel": "Cancel",

            "Advert Status": "Advert Status",
            "status.active": "You advert is currently active.",
            "status.suspended": "You advert is currently suspended. Please review your advert and make sure it complies with our <a href='/article/terms-of-use' target='_blank'>Terms and Conditions</a>.",
            "status.rejected": "You advert has been rejected. Please check our <a href='/article/terms-of-use' target='_blank'>Terms and Conditions</a>.",
            "status.review": "You advert is currently under review. Please wait for our team to review your advert.",
            "status.archived": "You advert has been archived. You can restore it buy clicking the Promote button.",
            "status.unknown": "We could not determine the status of your advert. Please contact us for more information.",

            "Are you sure you want to delete this review?": "Are you sure you want to delete this review?",
            "Are you sure you want to delete your account?": "Are you sure you want to delete your account?",

            //messages
            "Please enter your email": "Please enter your email",
            "Please enter your password": "Please enter your password",
            "Please enter your name": "Please enter your name",
            "Please enter your phone number": "Please enter your phone number",
            "Please enter your city": "Please enter your city",
            "Please enter your address": "Please enter your address",
            "Please enter your password confirmation": "Please enter your password confirmation",
            "Please select your category": "Please select your category",
            "Please select your subcategory": "Please select your subcategory",
            "Successfull Advert Post": "You have successfully created your ad. You can now publish it or save it as a draft.",
            "This field is required": "This field is required",
            "Please enter a valid email": "Please enter a valid email",
            "Please enter a Password": "Please enter a Password",
            "You did not pass the human test.": "You did not pass the human test.",
            "This item might not exist or is no longer available": "This item might not exist or is no longer available",
            "404": "404 - Page not found",
            "Please fill all fields": "Please fill all fields",
            "No new data provided": "No new data provided",
            "went wrong": "Something went wrong. Please try again.",
            "Advert Saved.": "Advert Saved.",
            "Checking data...": "Checking data...",
            "A Message is required": "A Message is required",
            "Message sent": "Message sent",
            "Please Rate the Seller": "Please Rate the Seller",
            "Review sent": "Review sent",
            "Something went wrong. Please try again later.": "Something went wrong. Please try again later.",
            "Report sent": "Report sent",
            "A Price is required": "A Price is required",
            "We could not upload the file. Please try again later.": "We could not upload the file. Please try again later.",
            "No file provided": "No file provided",
            "Image Updated!": "Image Updated!",
            "Please enter your email": "Please enter your email",
            "Ooops! Something went wrong. Please try again.": "Ooops! Something went wrong. Please try again.",
            "Wrong password, please try again.": "Wrong password, please try again.",
            "User with this phone number is already registered. Please login.": "User with this phone number is already registered. Please login.",
            "User with this username is already registered.": "User with this username is already registered.",
            "Registering User...": "Registering User...",
            "This email is not registered!": "This email is not registered!",
            "This Phone did not pass verifivation. Please try again.": "This Phone did not pass verifivation. Please try again.",
            "Ooops! Could not send verification code. Please try again.": "Ooops! Could not send verification code. Please try again.",
            "Could not send email.":"Could not send email.",
            "Your password has been sent to your email address!": "Your password has been sent to your email address!",
            "Old Password is incorrect!": "Old Password is incorrect!",
            "You did not pass the human test.": "You did not pass the human test.",
            "User Password Updated!": "User Password Updated!",
            "Images Updated!": "Images Updated!",
            "No images uploaded.": "No images uploaded.",
            "Your account was successfully created!": "Your account was successfully created!",
            "Please check your email for verification code!": "Please check your email for verification code!",
            "Your Phone is now Public!": "Your Phone is now Public!",
            "Your Phone is now Private!": "Your Phone is now Private!",
            "Your Advert is now Public": "Your Advert is now Public",
            "Error parsing generated description": "Error parsing generated description",
            "accountDeletedMessage": "Your account is deleted. Please contact the support team.",
            "accountInactiveMessage": "Your account is not active. Please contact the support team.",
            "accountBannedMessage": "Your account is banned. Please contact the support team.",
            "noAccountError": "There is no account with this phone. Please Register.",
            "Account Deleted": "Account Deleted",
            "Could not delete profile": "Could not delete profile",
            "no white spaces": "Should not contain any white spaces.",
            "username ANSI": "The username should only contain ANSI characters",
            "call you back": "We have received your request and will call you back shortly.",
            "search.no_results": "No results found for your search. Please try again.",
            "Please login to add to favorites": "Please login to add to favorites",

            //titles
            "Information": "Information",
            "Images": "Images",
            "Finish": "Images",
            "Title": "Title",
            "Category": "Category",
            "Views": "Views",
            "Actions": "Actions",
            "Active": "Active",
            //general
            "Select a category and subcategory": "Select a category and subcategory",
            "Use the AI generator to create a description for your product. To get the best result please fill in the product details.": "Use the AI generator to create a description for your product. To get the best result please fill in the product details.",
            "You are logged in as": "You are logged in as",
            "You have reached the maximum number of images allowed": "You have reached the maximum number of images allowed",
            "Drop your files here or click to upload": "Drop your files here or click to upload",
            "Upload Images from Your Phone": "Upload Images from Your Phone",
            "This user does not exist!": "This user does not exist!",
            "Scan QR with your mobile phone": "Scan QR with your mobile phone",
            "another registration method": "We had troubles sending the verification code.<br/>Would you like to register another way?",
            "username message": "This is your unique username that will be visible to everyone. Use your company or brand name if you are registering as a company.",
            "call registration subtitle": "Please use this form to register. We will call you to verify your phone number.",

            
        },
        ro: {
            //ivite page
            "Welcome to Salex": "Bine ai venit pe Salex",
            "Where opportunities and offers align!": "Unde oportunitățile și ofertele se aliniază!",
            "Enter your invitation code to continue": "Introduceți codul dvs. de invitație pentru a continua",
            "Invitation Code": "Cod de invitație",
            "Continue": "Continua",
            "For more information, please contact us at": "Pentru mai multe informații, vă rugăm să ne contactați la",
            "Invalid invitation code": "Cod de invitație invalid",

            //page titles
            "home.title": "Salex - Panou de anunțuri gratuite",
            "home.description": "Salex - Panou de anunțuri gratuite",
            "home.description": "Explorează Salex - hub-ul tău pentru anunțuri gratuite. Cumpără, vinde și descoperă o varietate de anunțuri de la electronice la imobiliare. Alătură-te comunității noastre pentru experiențe clasificate ușoare și eficiente.",

            "category.title": "Salex - Panou de anunțuri gratuite",
            "category.description": "Răsfoiți categorii diverse pe Salex: Găsiți și listați articole, servicii și imobiliare. Navigare ușoară, selecție vastă. Ridicați-vă experiența de cumpărare și vânzare.",

            "add.title": "Adaugă Anunț - Salex",
            "add.description": "Postează-ți anunțul pe Salex: rapid, simplu și gratuit. Ajungeți la un public larg cu listările dvs. în diverse categorii, de la electronice la imobiliare. Amplificați-vă vânzările astăzi!",

            "edit.title": "Editează Anunț - Salex",
            "edit.description": "Editați descrierile, actualizați detaliile și îmbunătățiți-vă listarea pentru o vizibilitate și angajament mai bune. Faceți ca anunțul dvs. să iasă în evidență!",

            "404.title": "404 - Pagina nu a fost gasita",
            "404.description": "Acest articol s-ar putea să nu existe sau să nu mai fie disponibil",

            "pricing.title": "Prețuri - Salex",
            "pricing.description": "Descoperiți planuri de preț accesibile pe Salex. Alegeți opțiunea perfectă pentru nevoile dvs. de publicitate, de la listări gratuite la funcții premium. Maximizați valoarea și ajungeți.",

            "recent.title": "Recent - Salex",
            "recent.description": "Explorați cele mai recente listări pe Salex: rămâneți înaintea celor mai recente anunțuri din diverse categorii. Găsiți oferte și oportunități proaspete în fiecare zi.",

            "search.title": "Cautare - Salex",
            "search.description": "Găsiți exact ceea ce aveți nevoie pe Salex cu căutarea noastră puternică. Descoperiți o gamă largă de listări din diverse categorii, adaptate preferințelor dvs.",

            "preview.title": "Previzualizare - Salex",
            "preview.description": "Previzualizați anunțul înainte de publicare. Asigurați-vă că totul este în ordine și gata de plecare.",

            "adverts.title": "Anunțurile mele - Salex",
            "adverts.description": "Gestionați anunțurile dvs. pe Salex: editați, promovați și ștergeți listările dvs.",

            "dashboard.title": "Panou de control - Salex",
            "dashboard.description": "Gestionați-vă contul pe Salex: actualizați-vă profilul, schimbați-vă parola și gestionați-vă notificările. Urmăriți-vă vânzările și performanța.",

            "messages.title": "Mesaje - Salex",
            "messages.description": "Gestionați mesajele dvs. pe Salex: vizualizați, răspundeți și ștergeți mesajele dvs.",

            "profile.title": "Profil - Salex",
            "profile.description": "Gestionați-vă profilul pe Salex: actualizați-vă fotografia de profil, schimbați-vă adresa de e-mail și gestionați-vă notificările.",

            "notifications.title": "Notificări - Salex",
            "notifications.description": "Gestionați-vă notificările pe Salex: actualizați setările și preferințele de notificare.",

            "reviews.title": "Recenzii - Salex",
            "reviews.description": "Gestionați-vă recenziile pe Salex: vizualizați, răspundeți și ștergeți recenziile dvs.",

            "favorites.title": "Favorite - Salex",
            "favorites.description": "Gestionați-vă favoritele pe Salex: vizualizați, adăugați și ștergeți favoritele dvs.",

            "helpdesk.title": "Ajutor - Salex",
            "helpdesk.description": "Salex Helpdesk - Suport și asistență pentru întrebările dvs.",

            //text
            "Hello": "Cine cauta, gaseste!",
            //menu
            "Home": "Acasa",
            "About": "Despre",
            "Contact": "Contact",
            "Login": "Autentificare",
            "Logout": "Iesire din cont",
            "Register": "Inregistrare",
            "Account": "Contul meu",
            "Adverts": "Anunturile mele",
            "Favorites": "Favorite",
            "Messages": "Mesaje",
            "Message": "Mesaj",
            "My Profile": "Profilul meu",
            "Bills": "Facturi",
            "History": "Istoric",
            "Payment Methods": "Metode de plata",
            "Notifications": "Notificari",
            "Password": "Parola",
            "Settings": "Setari",
            "Dashboard": "Panou de control",
            "Personal Data": "Date personale",
            "Reviews": "Recenzii",

            //footer sections
            "Information": "Informatii",
            "Customer Support": "Suport clienti",
            "Social Media": "Social Media",
            
            //interface elements
            "Search": "Cauta",
            "Location": "Locatie",
            "isType": "Tip",
            "Categories": "Categorii",
            "postAd": "Posteaza Anunt",
            "Generate Description": "Genereaza Descriere",
            "Show mobile": "Arata numarul de telefon catre cumparator?",
            "Save Draft": "Salveaza schița",
            "Publish": "Publica",
            "Previous": "Inapoi",
            "Next": "Inainte",
            "Images": "Imagini",
            "Drop images": "Trage imaginile aici sau apasa pentru a le incarca",
            "Continue to next step": "Continua la pasul urmator",
            "Cancel": "Anuleaza",
            "Save": "Salveaza",
            "Delete": "Sterge",
            "Back": "Inapoi",
            "Contact": "Contact",
            "Send Message": "Trimite Mesaj",
            "SMessage": "Trimite Mesaj",
            "Send": "Trimite",
            "Edit": "Editeaza",
            "View": "Vezi",
            "View Ad": "Vezi Anuntul",
            "View Profile": "Vezi Profilul",
            "View Message": "Vezi Mesajul",
            "Reply": "Raspunde",
            "Date": "Data",
            "View Gallery": "Vezi Galeria",
            "Advanced Search": "Cautare Avansata",
            "Close": "Inchide",
            "Apply Filter": "Aplică Filtru",
            "Clear": "Curata",
            "Clear filters": "Curata filtrele",
            "Grid": "Grid",
            "List": "Lista",
            "Reset Filter": "Reseteaza filtrele",
            "Total views": "Total vizualizari",
            "Report": "Reclamatie",
            "Your Recently Viewed Items": "Produsele vizualizate recent",
            "Similar Items": "Produse similare",
            "View All": "Vezi toate",
            "Restore Access": "Restabileste Accesul",
            "Resend Code": "Retrimite Codul",
            "Save Password": "Salveaza Parola",
            "Your Email": "Email-ul tau",
            "Your Phone": "Telefonul tau",
            "Verification code": "Cod de verificare",
            "Create New Password": "Creaza o noua parola",
            "Your New Password": "Noua ta parola",
            "Login Now": "Autentifica-te acum",
            "Try Another Phone Number": "Incearca alt numar de telefon",
            "Resend Code": "Retrimite Codul",
            "Save Password": "Salveaza Parola",
            "Your phone": "Telefonul tau",
            "Your password": "Parola ta",
            "Forgot password?": "Ai uitat parola?",
            "Create an Account?": "Inregistreaza-te acum",
            "Phone": "Telefon",
            "Password": "Parola",
            "Username": "Username",
            "Full Name": "Nume",
            "Address": "Adresa",
            "City": "Oras",
            "Change": "Schimba",
            "Change Email": "Schimba Email-ul",
            "Add": "Adauga",
            "Add Email": "Adauga Email",
            "Update": "Actualizeaza",
            "Change Password": "Schimba Parola",
            "Current Password": "Parola Curenta",
            "New Password": "Parola Noua",
            "Your answer": "Raspunsul tau",
            "Change Phone": "Schimba Telefonul",
            "Restore Acces": "Restabileste Accesul",
            "Help Desk": "Ajutor",
            "Grid": "Grid",
            "List": "Lista",
            "Incoming": "Primite",
            "Outgoing": "Trimise",
            "Type your message": "Scrie mesajul tau",
            "Chat": "Chat",
            "Map": "Harta",
            "Select Address": "Selecteaza Adresa",
            "Save Location": "Salveaza Locatia",
            "Generate": "Genereaza",
            "Mobile Uploader": "Incarcator Mobil",
            "Done": "Gata",
            "Upload Queue": "Coada de incarcare",
            "Remove": "Sterge",
            "Upload Images": "Incarca Imagini",
            "Main": "Principal",
            "Reset": "Reseteaza",
            "Add Images": "Adauga Imagini",
            "Filter": "Filtru",
            "Sort": "Sortare",
            "Help": "Ajutor",
            "Help Bot": "Bot Asistent",
            "Help Desk": "Ajutor",
            "Leave a Review": "Lasa un Review",
            "Contact information": "Informatii de contact",
            "Post a Review": "Posteaza un Review",
            "Post": "Posteaza",
            "stars": "stele",
            "by": "de la",
            "Send a Report": "Trimite o Reclamatie",
            "report1": "Informatii inselatoare sau false",
            "report2": "Continut inadecvat sau ofensator",
            "report3": "Activitate frauduloasa sau de escrocherie",
            "report4": "Continut irelevant sau off-topic",
            "report5": "Produse daunatoare sau periculoase",
            "report6": "Continut repetitiv sau spam",
            "report10": "Altele",
            "Send Offer": "Trimite Oferta",
            "Your Price": "Pretul tau",
            "Make an Offer": "Fă o Ofertă",
            "Generating Description": "Generare Descriere",
            "Upload": "Incarca",
            "Profile Picture": "Poza de Profil",
            "Upload Image": "Incarca Imagine",
            "Upload Avatar": "Incarca Avatar",
            "Your email": "Email-ul tau",
            "Flag": "Flag",
            "Add Filters": "Adauga Filtre",
            "No adverts found": "Nu s-au gasit anunturi",
            "View Subcategories": "Vezi Subcategoriile",
            "Hide": "Ascunde",
            "Negociable": "Negociabil",
            "Sort": "Sorteaza",
            "By Date: Newest": "După Dată: Cele Mai Noi",
            "By Date: Oldest": "După Dată: Cele Mai Vechi",
            "By Price: Low to High": "După Preţ: Crescător",
            "By Price: High to Low": "După Preţ: Descrescător",
            "No Sort": "Fara Sortare",
            "Status": "Status",
            "Preview": "Previzualizare",
            "Promote": "Promoveaza",
            "Temporarily Suspended": "Suspendat Temporar",
            "Rejected": "Respins",
            "Under Review": "In Revisal",
            "Archived": "Arhivat",
            "Unknown": "Necunoscut",
            "Published": "Publicat",
            "Add Advert": "Adauga Anunt",
            "Delete Account": "Sterge Contul",
            "Yes": "Da",
            "No": "Nu",
            "Manage Account": "Gestioneaza Contul",
            "Try Again?": "Incearca din nou?",
            "Register with a Callback": "Inregistrare cu un apel inapoi",



            //modals
            "Exchange Rates": "Curs Valutar",
            //sections
            "Details": "Detalii",
            "Select category": "Selecteaza categoria",
            "Select subcategory": "Selecteaza subcategoria",
            "Price": "Pret",
            "Description": "Descriere",
            "Advert Images": "Imagini Anunt",
            "Edit Your Advert": "Editeaza Anuntul Tau",
            //forms
            "Options": "Optiuni",
            "Category": "Categorie",
            "Subcategory": "Subcategorie",
            "Address": "Adresa",
            "Currency": "Moneda",
            "Select": "Selecteaza",
            "Select Model": "Selecteaza Model",
            "Model": "Model",
            "Title": "Titlu",
            "Description": "Descriere",
            "Price Max": "Pret Max",
            "Price Min": "Pret Min",
            "from": "de la",
            "to": "pana la",
            "Negotiable": "Negociabil",
            "Phone": "Telefon",
            "Email": "Email",
            "Create a new account": "Creeaza un cont nou",
            "Existing account login": "Autentificare in contul existent",
            "Account Type": "Tip Cont",
            "Personal": "Personal",
            "Company": "Companie",
            "Your Username": "Username-ul tau",
            "Your Password": "Parola ta",
            "Your Name": "Numele tau",
            "Your Phone": "Telefonul tau",
            
            //dialog messages & titles
            "Deleting Image": "Sterge Imaginea",
            "delete image message" : "Esti sigur ca vrei sa <b>stergi</b> aceasta imagine? Aceasta actiune nu poate fi anulata.",
            "Promote Advert": "Promoveaza Anuntul",
            "You can only promote your advert once per day.": "You can only promote your advert once per day.",
            "You have no promotions left for this Advert.": "You have no promotions left for this Advert.",
            "Are you sure you want to <b>promote</b> this advert?": "Are you sure you want to <b>promote</b> this advert?",
            "OK": "OK",
            "Promote": "Promoveaza",
            "Promote Advert": "Promoveaza Anuntul",
            "Are you sure you want to delete this advert? This action cannot be undone.": "Esti sigur ca vrei sa stergi acest anunt? Aceasta actiune nu poate fi anulata.",
            "You can only promote your advert once per day.": "You can only promote your advert once per day.",
            "Delete review": "Sterge Review",
            "Are you sure you want to delete this review? This action cannot be undone.": "Esti sigur ca vrei sa stergi acest review? Aceasta actiune nu poate fi anulata.",
            "Flag review": "Reclama Review",
            "Are you sure you want to flag this review?": "Esti sigur ca vrei sa reclami acest review?",
            "Are you sure you want to promote this advert?": "Esti sigur ca vrei sa promovezi acest anunt?",

            "cookie.title": "Politica Cookie",
            "cookie.message": "Acest site utilizează cookie-uri pentru a vă asigura că obțineți cea mai bună experiență pe site-ul nostru.",
            "cookie.confirm": "Am inteles!",
            "cookie.cancel": "Anuleaza",

            "Advert Status": "Status Anunt",
            "status.active": "Anuntul tau este activ.",
            "status.suspended": "Anuntul tau este suspendat. Te rugam sa verifici anuntul si sa te asiguri ca respecta <a href='/article/terms-of-use' target='_blank'>Termenii si Conditiile</a> noastre.",
            "status.rejected": "Anuntul tau a fost respins. Te rugam sa verifici <a href='/article/terms-of-use' target='_blank'>Termenii si Conditiile</a> noastre.",
            "status.review": "Anuntul tau este in curs de revizuire. Te rugam sa astepti ca echipa noastra sa revizuiasca anuntul.",
            "status.archived": "Anuntul tau a fost arhivat. Il poti restaura apasand butonul Promoveaza.",
            "status.unknown": "Nu am putut determina statusul anuntului tau. Te rugam sa ne contactezi pentru mai multe informatii.",

            "Are you sure you want to delete this review?": "Esti sigur ca vrei sa stergi acest review? Aceasta actiune nu poate fi anulata.",
            "Are you sure you want to delete your account?": "Esti sigur ca vrei sa stergi contul? Aceasta actiune nu poate fi anulata.",

            //messages
            "Please enter your email": "Introduceti adresa de email",
            "Please enter your password": "Introduceti parola",
            "Please enter your name": "Introduceti numele",
            "Please enter your phone number": "Introduceti numarul de telefon",
            "Please enter your city": "Introduceti orasul",
            "Please enter your address": "Introduceti adresa",
            "Please enter your password confirmation": "Introduceti confirmarea parolei",
            "Please select your category": "Selectati categoria",
            "Please select your subcategory": "Selectati subcategoria",
            "Successfull Advert Post": "Ati creat cu succes anuntul. Acum il puteti publica sau il puteti salva ca si ciorna.",
            "This field is required": "Acest camp este obligatoriu",
            "Please enter a valid email": "Introduceti o adresa de email valida",
            "Please enter a Password": "Introduceti o parola",
            "You did not pass the human test.": "Nu ati trecut testul de umanitate.",
            "This item might not exist or is no longer available": "Acest articol s-ar putea sa nu existe sau sa nu mai fie disponibil",
            "404": "404 - Pagina nu a fost gasita",
            "Please fill all fields": "Va rugam sa completati toate campurile",
            "No new data provided": "Nu au fost furnizate date noi",
            "went wrong": "Ceva nu a mers bine. Va rugam sa incercati din nou.",
            "Advert Saved.": "Anunt Salvat.",
            "Checking data...": "Verificare date...",
            "A Message is required": "Un Mesaj este necesar",
            "Message sent": "Mesaj trimis",
            "Please Rate the Seller": "Va rugam sa evaluati vanzatorul",
            "Review sent": "Review trimis",
            "Something went wrong. Please try again later.": "Ceva nu a mers bine. Va rugam sa incercati din nou mai tarziu.",
            "Report sent": "Reclamatie trimisa",
            "A Price is required": "Un Pret este necesar",
            "We could not upload the file. Please try again later.": "Nu am putut incarca fisierul. Va rugam sa incercati din nou mai tarziu.",
            "No file provided": "Nu a fost furnizat niciun fisier",
            "Image Updated!": "Imagine Actualizata!",
            "Please enter your email": "Introduceti adresa de email",
            "Ooops! Something went wrong. Please try again.": "Ooops! Ceva nu a mers bine. Va rugam sa incercati din nou.",
            "Wrong password, please try again.": "Parola gresita, va rugam sa incercati din nou.",
            "User with this phone number is already registered. Please login.": "Utilizatorul cu acest numar de telefon este deja inregistrat. Va rugam sa va autentificati.",
            "User with this username is already registered.": "Utilizatorul cu acest username este deja inregistrat.",
            "Registering User...": "Inregistrare Utilizator...",
            "This email is not registered!": "Aceasta adresa de email nu este inregistrata!",
            "This Phone did not pass verifivation. Please try again.": "Acest numar de telefon nu a trecut verificarea. Va rugam sa incercati din nou.",
            "Ooops! Could not send verification code. Please try again.": "Ooops! Nu am putut trimite codul de verificare. Va rugam sa incercati din nou.",
            "Could not send email.": "Nu am putut trimite emailul.",
            "Your password has been sent to your email address!": "Parola a fost trimisa la adresa dvs. de email!",
            "Old Password is incorrect!": "Parola Veche este incorecta!",
            "You did not pass the human test.": "Nu ati trecut testul de umanitate.",
            "User Password Updated!": "Parola Utilizatorului Actualizata!",
            "Images Updated!": "Imagini Actualizate!",
            "No images uploaded.": "Nu au fost incarcate imagini.",
            "Your account was successfully created!": "Contul dvs. a fost creat cu succes!",
            "Please check your email for verification code!": "Va rugam sa verificati emailul pentru codul de verificare!",
            "Your Phone is now Public!": "Telefonul tau este acum public!",
            "Your Phone is now Private!": "Telefonul tau este acum privat!", 
            "Your Advert is now Public": "Anuntul tau este acum public",  
            "Error parsing generated description": "Eroare la parsarea descrierii generate",
            "accountDeletedMessage": "Contul dvs. a fost sters. Va rugam sa contactati echipa de suport.",
            "accountInactiveMessage": "Contul dvs. nu este activ. Va rugam sa contactati echipa de suport.",
            "accountBannedMessage": "Contul dvs. este blocat. Va rugam sa contactati echipa de suport.",
            "noAccountError": "Nu exista un cont cu acest numar de telefon. Va rugam sa va inregistrati.",
            "Account Deleted": "Contul Sters",
            "Could not delete profile": "Nu am putut sterge profilul",
            "username no white spaces": "Nu ar trebui sa contina spatii albe",
            "username ANSI": "Username-ul ar trebui sa contina doar caractere ANSI",
            "call you back": "Am primit solicitarea dvs. si va vom suna inapoi in curand.",
            "search.no_results": "Nu s-au gasit rezultate pentru cautarea dvs.",
            "Please login to add to favorites": "Va rugam sa va autentificati pentru a adauga la favorite",

            //titles
            "Information": "Informatii",
            "Images": "Imagini",
            "Finish": "Finalizare",
            "Title": "Titlu",
            "Category": "Categorie",
            "Views": "Vizualizari",
            "Actions": "Actiuni",
            "Active": "Activ",
            //general
            "Select a category and subcategory": "Selectati o categorie si o subcategorie",
            "Use the AI generator to create a description for your product. To get the best result please fill in the product details.": "Folositi generatorul AI pentru a crea o descriere pentru produsul dumneavoastra. Pentru a obtine cel mai bun rezultat va rugam sa completati detaliile produsului.",
            "You are logged in as": "Sunteti autentificat ca",
            "You have reached the maximum number of images allowed": "Ati atins numarul maxim de imagini permis",
            "Drop your files here or click to upload": "Trage fisierele aici sau apasa pentru a le incarca",
            "Upload Images from Your Phone": "Incarca imagini de pe telefonul tau",
            "This user does not exist!": "Acest utilizator nu exista!",
            "Scan QR with your mobile phone": "Scaneaza codul QR cu telefonul mobil",
            "another registration method": "Am intampinat probleme la trimiterea codului de verificare.<br/>Doriti sa va inregistrati printr-o alta metoda?",
            "username message": "Acesta este username-ul dvs. unic care va fi vizibil pentru toata lumea. Folositi numele companiei sau brandului daca va inregistrati ca si companie.",
            "call registration subtitle": "Va rugam sa folositi acest formular pentru a va inregistra. Va vom suna pentru a va verifica numarul de telefon.",


        },
        ru: {
            //ivite page
            "Welcome to Salex": "Добро пожаловать на Salex",
            "Where opportunities and offers align!": "Где возможности и предложения совпадают!",
            "Enter your invitation code to continue": "Введите свой код приглашения, чтобы продолжить",
            "Invitation Code": "Код приглашения",
            "Continue": "Продолжить",
            "For more information, please contact us at": "Для получения дополнительной информации свяжитесь с нами по адресу",
            "Invalid invitation code": "Неверный код приглашения",

            //page titles
            "home.title": "Salex - Бесплатные объявления",
            "home.description": "Исследуйте Салекс — ваш центр бесплатных объявлений. Покупайте, продавайте и открывайте для себя множество предложений: от электроники до недвижимости. Присоединяйтесь к нашему сообществу, чтобы получить простой и эффективный классифицированный опыт.",

            "category.title": "Salex - Бесплатные объявления",
            "category.description": "Просмотрите различные категории на Salex: найдите и разместите товары, услуги и недвижимость. Легкая навигация, огромный выбор. Повысьте свой опыт покупки и продажи.",

            "add.title": "Добавить Объявление - Salex",
            "add.description": "Разместите свое объявление на Salex: быстро, просто и бесплатно. Достигните широкой аудитории с вашими объявлениями в различных категориях, от электроники до недвижимости. Увеличьте свои продажи уже сегодня!",

            "edit.title": "Редактировать Объявление - Salex",
            "edit.description": "Редактировать описания, обновлять детали и улучшать свое объявление для лучшей видимости и вовлеченности. Сделайте свое объявление более заметным!",

            "404.title": "404 - Страница не найдена",
            "404.description": "Этот товар может не существовать или больше не доступен",

            "pricing.title": "Цены - Salex",
            "pricing.description": "Изучите доступные ценовые планы Salex. Выберите идеальный вариант для ваших рекламных потребностей, от бесплатных объявлений до премиальных функций. Максимизируйте ценность и достигайте.",

            "recent.title": "Недавние - Salex",
            "recent.description": "Изучите последние объявления на Salex: оставайтесь впереди последних объявлений в различных категориях. Найдите свежие предложения и возможности каждый день.",

            "search.title": "Поиск - Salex",
            "search.description": "Найдите то, что вам нужно на Salex с нашим мощным поиском. Исследуйте широкий спектр объявлений в различных категориях, подходящих вашим предпочтениям.",

            "preview.title": "Предварительный просмотр - Salex",
            "preview.description": "Предварительный просмотр вашего объявления на Salex. Проверьте, как будет выглядеть ваше объявление, прежде чем опубликовать его.",

            "adverts.title": "Мои Объявления - Salex",
            "adverts.description": "Управляйте своими объявлениями на Salex: редактируйте, продвигайте и удаляйте свои объявления.",

            "dashboard.title": "Панель управления - Salex",
            "dashboard.description": "Управляйте своей учетной записью на Salex: обновляйте свой профиль, меняйте пароль и управляйте уведомлениями. Отслеживайте свои продажи и производительность.",

            "messages.title": "Сообщения - Salex",
            "messages.description": "Управляйте своими сообщениями на Salex: просматривайте, отвечайте и удаляйте свои сообщения.",

            "profile.title": "Профиль - Salex",
            "profile.description": "Управляйте своим профилем на Salex: обновляйте свое фото профиля, меняйте свой адрес электронной почты и управляйте уведомлениями.",

            "notifications.title": "Уведомления - Salex",
            "notifications.description": "Управляйте своими уведомлениями на Salex: обновляйте настройки и предпочтения уведомлений.",

            "reviews.title": "Отзывы - Salex",
            "reviews.description": "Управляйте своими отзывами на Salex: просматривайте, отвечайте и удаляйте свои отзывы.",

            "favorites.title": "Избранное - Salex",
            "favorites.description": "Управляйте своими избранными на Salex: просматривайте, добавляйте и удаляйте свои избранные.",

            "helpdesk.title": "Помощь - Salex",
            "helpdesk.description": "Salex Helpdesk - поддержка и помощь для ваших вопросов.",

            //text
            "Hello": "Кто ищет, тот всегда найдет!",
            //menu
            "Home": "Главная",
            "About": "О нас",
            "Contact": "Контакты",
            "Login": "Вход",
            "Logout": "Выход",
            "Register": "Регистрация",
            "Account": "Мой аккаунт",
            "Adverts": "Мои объявления",
            "Favorites": "Избранное",
            "Messages": "Сообщения",
            "Message": "Сообщение",
            "My Profile": "Мой профиль",
            "Bills": "Счета",
            "History": "История",
            "Payment Methods": "Способы оплаты",
            "Notifications": "Уведомления",
            "Password": "Пароль",
            "Settings": "Настройки",
            "Dashboard": "Панель управления",
            "Personal Data": "Персональные данные",
            "Reviews": "Отзывы",

            //footer sections
            "Information": "Информация",
            "Customer Support": "Поддержка клиентов",
            "Social Media": "Социальные сети",
            
            //interface elements
            "Search": "Поиск",
            "Location": "Локация",
            "isType": "Тип",
            "Categories": "Категории",
            "postAd": "Разместить Объявление",
            "Generate Description": "Сгенерировать Описание",
            "Show mobile": "Показать ваш номер телефона покупателю?",
            "Save Draft": "Сохранить черновик",
            "Publish": "Опубликовать",
            "Previous": "Назад",
            "Next": "Далее",
            "Images": "Изображения",
            "Drop images": "Перетащите изображения сюда или нажмите для загрузки",
            "Continue to next step": "Продолжить к следующему шагу",
            "Cancel": "Отмена",
            "Save": "Сохранить",
            "Delete": "Удалить",
            "Back": "Назад",
            "Contact": "Контакт",
            "Send Message": "Отправить Сообщение",
            "SMessage": "Сообщение",
            "Send": "Отправить",
            "Edit": "Редактировать",
            "View": "Просмотр",
            "View Ad": "Просмотреть Объявление",
            "View Profile": "Просмотреть Профиль",
            "View Message": "Просмотреть Сообщение",
            "Reply": "Ответить",
            "Date": "Дата",
            "View Gallery": "Просмотреть Галерею",
            "Advanced Search": "Расширенный Поиск",
            "Close": "Закрыть",
            "Apply Filter": "Применить",
            "Clear": "Очистить",
            "Clear Filter": "Очистить фильтр",
            "Grid": "Сетка",
            "List": "Список",
            "Reset Filter": "Сбросить фильтр",
            "Total views": "Всего просмотров",
            "Report": "Пожаловаться",
            "Your Recently Viewed Items": "Недавно просмотренные",
            "Similar Items": "Похожие товары",
            "View All": "Просмотреть все",
            "Restore Access": "Восстановить доступ",
            "Resend Code": "Отправить код повторно",
            "Save Password": "Сохранить пароль",
            "Your Email": "Ваш Email",
            "Your Phone": "Ваш Телефон",
            "Verification code": "Код подтверждения",
            "Create New Password": "Создать новый пароль",
            "Your New Password": "Ваш новый пароль",
            "Login Now": "Войти сейчас",
            "Try Another Phone Number": "Попробуйте другой номер телефона",
            "Resend Code": "Отправить код повторно",
            "Save Password": "Сохранить пароль",
            "Your phone": "Ваш телефон",
            "Your password": "Ваш пароль",
            "Forgot password?": "Забыли пароль?",
            "Create an Account?": "Создать аккаунт?",
            "Phone": "Телефон",
            "Password": "Пароль",
            "Username": "Имя пользователя",
            "Full Name": "Полное имя",
            "Address": "Адрес",
            "City": "Город",
            "Change": "Изменить",
            "Change Email": "Изменить Email",
            "Add": "Добавить",
            "Add Email": "Добавить Email",
            "Update": "Обновить",
            "Change Password": "Изменить Пароль",
            "Current Password": "Текущий Пароль",
            "New Password": "Новый Пароль",
            "Your answer": "Ваш ответ",
            "Change Phone": "Изменить Телефон",
            "Restore Acces": "Восстановить доступ",
            "Help Desk": "Помощь",
            "Grid": "Сетка",
            "List": "Список",
            "Incoming": "Входящие",
            "Outgoing": "Исходящие",
            "Type your message": "Введите ваше сообщение",
            "Chat": "Чат",
            "Map": "Карта",
            "Select Address": "Выберите Адрес",
            "Save Location": "Сохранить Местоположение",
            "Generate": "Сгенерировать",
            "Mobile Uploader": "Мобильный загрузчик",
            "Done": "Готово",
            "Upload Queue": "Очередь загрузки",
            "Remove": "Удалить",
            "Upload Images": "Загрузить изображения",
            "Main": "Главное",
            "Reset": "Сбросить",
            "Add Images": "Добавить Изображения",  
            "Filter": "Фильтр",
            "Sort": "Сортировка",
            "Help": "Помощь",
            "Help Bot": "Бот Помошник",
            "Help Desk": "Помощь",            
            "Leave a Review": "Оставить Отзыв",
            "Contact information": "Контактная информация",
            "Post a Review": "Оставить Отзыв",
            "Post": "Отправить",
            "stars": "звезды",
            "by": "от",
            "Send a Report": "Отправить жалобу",
            "report1": "Мошенничество или ложная информация",
            "report2": "Неуместный или оскорбительный контент",
            "report3": "Мошенничество или обман",
            "report4": "Неуместный или неактуальный контент",
            "report5": "Вредные или опасные товары",
            "report6": "Повторяющийся или спам-контент",
            "report10": "Другое",
            "Send Offer": "Отправить Предложение",
            "Your Price": "Ваша Цена",
            "Make an Offer": "Сделать Предложение",
            "Generating Description": "Генерация Описания",
            "Upload": "Загрузить",
            "Profile Picture": "Изображение Профиля",
            "Upload Image": "Загрузить Изображение",
            "Upload Avatar": "Загрузить Аватар",
            "Your email": "Ваш Email",
            "Flag": "Flag",
            "Add Filters": "Добавить Фильтры",
            "No adverts found": "Объявления не найдены",
            "View Subcategories": "Просмотреть Подкатегории",
            "Hide": "Скрыть",
            "Negociable": "Торг",
            "Sort": "Сортировать",
            "By Date: Newest": "По Дате: Самые Новые",
            "By Date: Oldest": "По Дате: Самые Старые",
            "By Price: Low to High": "По Цене: Возрастающие",
            "By Price: High to Low": "По Цене: Убывающие",
            "No Sort": "Без Сортировки",
            "Status": "Статус",
            "Preview": "Предпросмотр",
            "Promote": "Продвигать",
            "Temporarily Suspended": "Временно Приостановлено",
            "Rejected": "Отклонено",
            "Under Review": "На Рассмотрении",
            "Archived": "В Архиве",
            "Unknown": "Неизвестно",
            "Published": "Опубликовано",
            "Add Advert": "Добавить",
            "Delete Account": "Удалить Аккаунт",
            "Yes": "Да",
            "No": "Нет",
            "Manage Account": "Управление Аккаунтом",
            "Try Again?": "Попробовать снова?",
            "Register with a Callback": "Регистрация с обратным вызовом",


            //modals
            "Exchange Rates": "Курсы Валют",
            //sections
            "Details": "Детали",
            "Select category": "Выберите категорию",
            "Select subcategory": "Выберите подкатегорию",
            "Price": "Цена",
            "Description": "Описание",
            "Advert Images": "Изображения Объявления",
            "Edit Your Advert": "Редактировать Ваше Объявление",
            //forms
            "Options": "Опции",
            "Category": "Категория",
            "Subcategory": "Подкатегория",
            "Address": "Адрес",
            "Currency": "Валюта",
            "Select": "Выбрать",
            "Select Model": "Выбрать Модель",
            "Model": "Модель",
            "Title": "Заголовок",
            "Description": "Описание",
            "Price Max": "Максимальная Цена",
            "Price Min": "Минимальная Цена",
            "from": "от",
            "to": "до",
            "Negotiable": "Договорная",
            "Phone": "Телефон",
            "Email": "Электронная почта",
            "Create a new account": "Создать новый аккаунт",
            "Existing account login": "Вход в существующий аккаунт",
            "Account Type": "Тип аккаунта",
            "Personal": "Личный",
            "Company": "Компания",
            "Your Username": "Ваш Username",
            "Your Password": "Ваш Пароль",
            "Your Name": "Ваше Имя",
            "Your Phone": "Ваш Телефон",
            
            //dialog messages & titles
            "Deleting Image": "Удаление Изображения",
            "delete image message" : "Вы уверены, что хотите <b>удалить</b> это изображение? Это действие нельзя отменить.",
            "Promote Advert": "Продвинуть Объявление",
            "You can only promote your advert once per day.": "Вы можете продвигать свое объявление только один раз в день.",
            "You have no promotions left for this Advert.": "У вас не осталось продвижений для этого объявления.",
            "Are you sure you want to <b>promote</b> this advert?": "Вы уверены, что хотите <b>продвинуть</b> это объявление?",
            "OK": "OK",
            "Promote": "Продвинуть",
            "Promote Advert": "Продвинуть Объявление",
            "Are you sure you want to delete this advert? This action cannot be undone.": "Вы уверены, что хотите удалить это объявление? Это действие нельзя отменить.",
            "You can only promote your advert once per day.": "Вы можете продвигать свое объявление только один раз в день.",
            "Delete review": "Удалить отзыв",
            "Are you sure you want to delete this review? This action cannot be undone.": "Вы уверены, что хотите удалить этот отзыв? Это действие нельзя отменить.",
            "Flag review": "Пожаловаться на отзыв",
            "Are you sure you want to flag this review?": "Вы уверены, что хотите пожаловаться на этот отзыв?",
            "Are you sure you want to promote this advert?": "Вы уверены, что хотите продвинуть это объявление?",

            "cookie.title": "Политика Cookie",
            "cookie.message": "Этот сайт использует файлы cookie, чтобы гарантировать, что вы получаете лучший опыт на нашем сайте.",
            "cookie.confirm": "Я понял!",
            "cookie.cancel": "Отмена",

            "Advert Status": "Статус Объявления",
            "status.active": "Ваше объявление активно.",
            "status.suspended": "Ваше объявление приостановлено. Пожалуйста, проверьте объявление и убедитесь, что оно соответствует нашим <a href='/article/terms-of-use' target='_blank'>Условиям Использования</a>.",
            "status.rejected": "Ваше объявление было отклонено. Пожалуйста, проверьте наши <a href='/article/terms-of-use' target='_blank'>Условия Использования</a>.",
            "status.review": "Ваше объявление находится на рассмотрении. Пожалуйста, дождитесь, пока наша команда проверит объявление.",
            "status.archived": "Ваше объявление было архивировано. Вы можете восстановить его, нажав кнопку Продвинуть.",
            "status.unknown": "Мы не смогли определить статус вашего объявления. Пожалуйста, свяжитесь с нами для получения дополнительной информации.",

            "Are you sure you want to delete this review?": "Вы уверены, что хотите удалить этот отзыв? Это действие нельзя отменить.",
            "Are you sure you want to delete your account?": "Вы уверены, что хотите удалить свой профиль? Это действие нельзя отменить.",

            //messages
            "Please enter your email": "Пожалуйста, введите ваш адрес электронной почты",
            "Please enter your password": "Пожалуйста, введите ваш пароль",
            "Please enter your name": "Пожалуйста, введите ваше имя",
            "Please enter your phone number": "Пожалуйста, введите ваш номер телефона",
            "Please enter your city": "Пожалуйста, введите ваш город",
            "Please enter your address": "Пожалуйста, введите ваш адрес",
            "Please enter your password confirmation": "Пожалуйста, введите подтверждение пароля",
            "Please select your category": "Пожалуйста, выберите свою категорию",
            "Please select your subcategory": "Пожалуйста, выберите свою подкатегорию",
            "Successfull Advert Post": "Вы успешно создали объявление. Теперь вы можете опубликовать его или сохранить как черновик.",
            "This field is required": "Это поле обязательно для заполнения",
            "Please enter a valid email": "Пожалуйста, введите действительный адрес электронной почты",
            "Please enter a Password": "Пожалуйста, введите пароль",
            "You did not pass the human test.": "Вы не прошли тест человека.",
            "This item might not exist or is no longer available": "Этот элемент может не существовать или больше не доступен",
            "404": "404 - Страница не найдена",
            "Please fill all fields": "Пожалуйста, заполните все поля",
            "No new data provided": "Нет новых данных",
            "went wrong": "Что-то пошло не так. Пожалуйста, попробуйте еще раз.",
            "Advert Saved.": "Объявление Сохранено.",
            "Checking data...": "Проверка данных...",
            "A Message is required": "Сообщение обязательно",
            "Message sent": "Сообщение отправлено",
            "Please Rate the Seller": "Пожалуйста, оцените продавца",
            "Review sent": "Отзыв отправлен",
            "Something went wrong. Please try again later.": "Что-то пошло не так. Пожалуйста, попробуйте еще раз позже.",
            "Report sent": "Отчет отправлен",
            "A Price is required": "Цена обязательна",
            "We could not upload the file. Please try again later.": "Мы не смогли загрузить файл. Пожалуйста, попробуйте еще раз позже.",
            "No file provided": "Файл не предоставлен",
            "Image Updated!": "Изображение обновлено!",
            "Error Updated Image!": "Ошибка обновления изображения!",
            "Please enter your email": "Пожалуйста, введите ваш адрес электронной почты",
            "Ooops! Something went wrong. Please try again.": "Ой! Что-то пошло не так. Пожалуйста, попробуйте еще раз.",
            "Wrong password, please try again.": "Неверный пароль, пожалуйста, попробуйте еще раз.",
            "User with this phone number is already registered. Please login.": "Пользователь с таким номером телефона уже зарегистрирован. Пожалуйста, войдите.",
            "User with this username is already registered.": "Пользователь с таким именем пользователя уже зарегистрирован.",
            "Registering User...": "Регистрация пользователя...",
            "This email is not registered!": "Этот адрес электронной почты не зарегистрирован!",
            "This Phone did not pass verifivation. Please try again.": "Этот телефон не прошел проверку. Пожалуйста, попробуйте еще раз.",
            "Ooops! Could not send verification code. Please try again.": "Ой! Не удалось отправить код подтверждения. Пожалуйста, попробуйте еще раз.",
            "Could not send email.": "Не удалось отправить электронное письмо.",
            "Your password has been sent to your email address!": "Ваш пароль был отправлен на ваш адрес электронной почты!",
            "Old Password is incorrect!": "Старый пароль неверен!",
            "You did not pass the human test.": "Вы не прошли тест человека.",
            "User Password Updated!": "Пароль пользователя обновлен!",
            "Images Updated!": "Изображения обновлены!",
            "No images uploaded.": "Изображения не загружены.",
            "Your account was successfully created!": "Ваша учетная запись была успешно создана!",
            "Please check your email for verification code!": "Пожалуйста, проверьте свою электронную почту на наличие кода подтверждения!",
            "Your Phone is now Public!": "Ваш телефон теперь публичный!",
            "Your Phone is now Private!": "Ваш телефон теперь приватный!",
            "Your Advert is now Public": "Ваше объявление теперь публичное",
            "Error parsing generated description": "Ошибка при разборе сгенерированного описания",
            "accountDeletedMessage": "Ваша учетная запись удалена. Пожалуйста, свяжитесь с командой поддержки.",
            "accountInactiveMessage": "Ваша учетная запись не активна. Пожалуйста, свяжитесь с командой поддержки.",
            "accountBannedMessage": "Ваша учетная запись заблокирована. Пожалуйста, свяжитесь с командой поддержки.",
            "noAccountError": "Нет аккаунта с этим телефоном. Пожалуйста, зарегистрируйтесь.",
            "Account Deleted": "Учетная запись удалена",
            "Could not delete profile": "Не удалось удалить профиль",
            "username no white spaces": "Не должно содержать пробелов",
            "username ANSI": "Имя пользователя должно содержать только символы ANSI",
            "call you back": "Мы получили ваш запрос и перезвоним вам в ближайшее время.",
            "search.no_results": "По вашему запросу ничего не найдено. Попробуйте изменить параметры поиска.",
            "Please login to add to favorites": "Пожалуйста, войдите, чтобы добавить в избранное",

            //titles
            "Information":"Информация",
            "Images":"Изображения",
            "Finish":"Завершить",
            "Title": "Заголовок",
            "Category": "Категория",
            "Views": "Просмотры",
            "Actions": "Действия",
            "Active": "Активный",

            //general
            "Select a category and subcategory": "Выберите категорию и подкатегорию",
            "Use the AI generator to create a description for your product. To get the best result please fill in the product details.": "Используйте генератор AI для создания описания для вашего продукта. Чтобы получить лучший результат, пожалуйста, заполните детали продукта.",
            "You are logged in as": "Вы вошли как",
            "You have reached the maximum number of images allowed": "Вы достигли максимального количества разрешенных изображений",
            "Drop your files here or click to upload": "Перетащите файлы сюда или нажмите, чтобы загрузить",
            "Upload Images from Your Phone": "Загрузить изображения с вашего телефона",
            "This user does not exist!": "Такого пользователя не существует!",
            "Scan QR with your mobile phone": "Отсканируйте QR-код с помощью мобильного телефона",
            "another registration method": "Мы столкнулись с проблемой отправки кода подтверждения.<br/>Хотите зарегистрироваться другим способом?",
            "username message": "Это ваш уникальный идентификатор, который будет виден всем. Используйте имя вашей компании или бренда, если вы регистрируетесь как компания.",
            "call registration subtitle": "Пожалуйста, используйте эту форму для регистрации. Мы позвоним вам, чтобы проверить ваш номер телефона.",

        },
    },
});